import React, { useState } from 'react';
import { CLOSED_ID, DEFAULT_AVATAR, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import { RouteRolesEnum } from '@gtintel/platform-types';
import { getConversationName } from '../../..';
import { getStartIndex } from '../../../utils';
import ChatHeader from '../../molecules/ChatHeader';
import ChatPdf from '../../molecules/ChatPdf';
import NewMessageForm from '../../organisms/NewMessageForm';
import ChatContent from '../ChatContent';
import * as S from './styles';
const CLOSED_TEXT = 'This project has now been closed. You are the only member of this conversation.';
const CLOSED_TEXT_CLIENT = 'This project has been closed and this chat channel is no longer active. Users on this project will not receive any new messages sent in this channel.';
const ConversationPanel = ({
  conversation,
  currentUserRole,
  getMoreMessages,
  messages,
  messagesEndRef,
  project,
  projectId,
  refreshToken,
  twilioClient,
  user
}) => {
  const [showPdfModal, setShowPdfModal] = useState(false);
  const isClosed = project && project.status === CLOSED_ID;
  const conversationAttrs = conversation.attributes;
  const conversationName = getConversationName({
    conversation,
    routeRole: currentUserRole,
    project
  });
  const closedText = currentUserRole === RouteRolesEnum.CLIENT ? CLOSED_TEXT_CLIENT : CLOSED_TEXT;
  return <>
      <S.Container>
        <ChatHeader currentUserRole={currentUserRole} image={DEFAULT_AVATAR} project={project} showPdfModal={() => setShowPdfModal(true)} title={conversationName} />
        <ChatContent endRef={messagesEndRef} getMoreMessages={getMoreMessages} messages={messages} twilioClient={twilioClient} user={user} />

        {isClosed && <S.CenterText>{closedText}</S.CenterText>}
        <NewMessageForm conversation={conversation} currentUser={user} isAdmin={twilioClient?.user.identity === TWILIO_ADMIN_IDENTITY} isDisabled={isClosed} onSendFailure={refreshToken} projectId={projectId} startIndex={getStartIndex(messages)} />
      </S.Container>
      <ChatPdf currentUserId={user.id} isVisible={showPdfModal} messages={messages} onCancel={() => setShowPdfModal(false)} onOk={() => setShowPdfModal(false)} title={conversationAttrs.project_name} />
    </>;
};
export default ConversationPanel;