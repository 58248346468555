import { addMinutes, format } from 'date-fns';
import { ADMIN_AVATAR, DEFAULT_NOTIFICATION_DATE_TIME_NOW, GTI_STAFF, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import firebase from './initializeFirebase';
const createNotification = (data, list_receiver = []) => {
  return Promise.all(list_receiver.map(receiver => {
    data.receiver_id = receiver.id;
    data.receiverName = receiver.name;
    const chil_receiver = firebase.database().ref(`${data.receiver_id}`);
    return Promise.resolve(chil_receiver.push().update({
      ...data
    }));
  }));
};
export async function sendNotifications({
  user,
  project,
  content,
  redirectLink,
  sendToClient = false,
  sendToAdmin = false,
  sendToPartner = false,
  sendToCustomReceivers = []
}) {
  const now = new Date();
  const notificationParam = {
    sender_id: user?.id,
    senderName: `${user?.first_name} ${user?.last_name}`,
    senderAvatar: user?.avatar,
    content,
    project_id: project?.id,
    project_name: project?.name,
    status: false,
    noti_badge: false,
    redirect_link: redirectLink ?? `/project/proposal/${project?.id}`,
    created_at: format(addMinutes(now, now.getTimezoneOffset()), DEFAULT_NOTIFICATION_DATE_TIME_NOW)
  };
  if (sendToAdmin) {
    const adminAsReceiver = [{
      id: TWILIO_ADMIN_IDENTITY,
      name: GTI_STAFF
    }];
    await createNotification(notificationParam, adminAsReceiver);
  }
  if (sendToClient) {
    const receivers = project?.members?.length ? project.members : project.client ? [project.client] : [];
    notificationParam.redirect_link = redirectLink ?? `/project/detail/${project?.id}`;
    await Promise.all(receivers.map(async receiver => {
      if (receiver?.id === user?.id) {
        return;
      }
      const clientAsReceiver = [{
        id: receiver.id,
        name: `${project?.client?.first_name} ${project?.client?.last_name}`
      }];
      return createNotification(notificationParam, clientAsReceiver);
    }));
  }
  if (sendToPartner && project?.partner?.id) {
    const partnerAsReceiver = [{
      id: project.partner.id,
      name: `${project?.partner?.first_name} ${project?.partner?.last_name}`
    }];
    notificationParam.redirect_link = redirectLink ?? `/project/detail/${project?.id}`;
    await createNotification(notificationParam, partnerAsReceiver);
  }
  if (sendToCustomReceivers?.length) {
    notificationParam.redirect_link = redirectLink ?? `/project/detail/${project?.id}`;
    await Promise.all(sendToCustomReceivers.map(async receiver => createNotification(notificationParam, [receiver])));
  }
}
const ADMIN_USER = {
  id: TWILIO_ADMIN_IDENTITY,
  avatar: ADMIN_AVATAR,
  first_name: 'GTI',
  last_name: 'Staff'
};
export async function sendNotificationsAsAdmin(params) {
  return sendNotifications({
    ...params,
    user: ADMIN_USER
  });
}