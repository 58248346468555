import React from 'react';
import { ArrowUpIcon, DownloadIcon } from 'react-line-awesome';
import { faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarTextCharacter, StarIcon } from '@gtintel/platform-atoms';
import { ButtonContainer, ButtonMainColors, HeadDiv, TitleDiv } from './styles';
const BLUE = '#4D7CFE';
const GOLD = '#FFBA00';
const Head = ({
  charIcon,
  closeChat,
  downloadMessages,
  id,
  isActive,
  isAdmin,
  isDM,
  isStarred,
  onClickToggle,
  setIsStarred,
  title
}) => {
  const backgroundColor = isDM ? GOLD : BLUE;
  const bgTitle = isDM ? BLUE : GOLD;
  return <HeadDiv style={{
    backgroundColor
  }}>
      <a href="#" onClick={() => onClickToggle(id)}>
        <TitleDiv>
          <AvatarTextCharacter bgTitle={bgTitle} name={charIcon} size="38" style={{
          marginRight: '13px'
        }} />
          {title}
        </TitleDiv>
      </a>
      <ButtonContainer>
        {isActive ? <>
            {isAdmin && <a href="#">
                <StarIcon chatPopup checked={isStarred} clickAction={() => setIsStarred(!isStarred)} />
              </a>}

            <ButtonMainColors onClick={() => downloadMessages()}>
              <DownloadIcon style={{
            color: 'white !important'
          }} />
            </ButtonMainColors>

            <ButtonMainColors onClick={() => onClickToggle(id)}>
              <FontAwesomeIcon style={{
            color: 'white !important',
            marginRight: '14px'
          }} icon={faMinus} />
            </ButtonMainColors>
            {!isDM && <ButtonMainColors onClick={() => closeChat()}>
                <FontAwesomeIcon style={{
            color: 'white !important',
            width: '14px'
          }} icon={faTimes} />
              </ButtonMainColors>}
          </> : <ArrowUpIcon onClick={() => onClickToggle(id)} style={{
        color: 'white !important'
      }} />}
      </ButtonContainer>
    </HeadDiv>;
};
export default Head;