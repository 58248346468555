import firebase from 'firebase';
import 'firebase/database';
import { getFirebaseToken } from '@gtintel/platform-api';
const firebaseConfig = {
  apiKey: process?.env?.FIREBASE_API_KEY,
  authDomain: process?.env?.FIREBASE_AUTH_DOMAIN,
  databaseURL: process?.env?.FIREBASE_DATABASE_URL,
  projectId: process?.env?.FIREBASE_PROJECT_ID,
  storageBucket: process?.env?.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process?.env?.FIREBASE_MESSAGING_SENDER_ID,
  appId: process?.env?.FIREBASE_APP_ID
};
const firebaseConfigMessage = {
  apiKey: process?.env?.MESSAGES_FIREBASE_API_KEY,
  authDomain: process?.env?.MESSAGES_FIREBASE_AUTH_DOMAIN,
  databaseURL: process?.env?.MESSAGES_FIREBASE_DATABASE_URL,
  projectId: process?.env?.MESSAGES_FIREBASE_PROJECT_ID,
  storageBucket: process?.env?.MESSAGES_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process?.env?.MESSAGES_FIREBASE_MESSAGING_SENDER_ID,
  appId: process?.env?.MESSAGES_FIREBASE_APP_ID,
  measurementId: process?.env?.MESSAGES_FIREBASE_MEASUREMENT_ID
};
const MINUTES_55 = 1000 * 60 * 55;
const authorizeFirebaseInstances = async () => {
  await getFirebaseToken().then(tokens => {
    firebase.apps.forEach(app => {
      firebase.auth(app).signInWithCustomToken(app.name === 'message-dev' ? tokens.messaging_token : tokens.projects_token).catch(() => {
        /* noop */
      });
    });
    // Tokens expire after 1 hour so must be refreshed
    if (firebase.apps.length) {
      setTimeout(() => {
        authorizeFirebaseInstances();
      }, MINUTES_55);
    }
  }).catch(() => {
    /* noop */
  });
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.initializeApp(firebaseConfigMessage, 'message-dev');
  authorizeFirebaseInstances();
}
export default firebase;