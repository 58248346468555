import React from 'react';
import { Badge } from 'antd';
import { LoadingSpinner } from '@gtintel/platform-atoms';
import { ButtonContainer } from './styles';
export default function ChatButton({
  chatName,
  channelSid,
  htmlId,
  icon,
  isDisabled,
  isInitializationFailed,
  isLoading,
  messageCount,
  onClick,
  isUserAdmin
}) {
  const channelNotFoundError = isUserAdmin && !channelSid;
  // sid may be nil if there was an error on project creation. If so, make sure
  // admins can see the problem:
  let text;
  if (isInitializationFailed) {
    text = 'ERROR';
  } else {
    text = chatName;
    if (channelNotFoundError) text += ' (ERROR: channel not found)';
  }
  return <Badge count={messageCount} offset={[-2, 2]}>
      <ButtonContainer disabled={isDisabled || isLoading || isInitializationFailed} data-testid={htmlId} onClick={onClick}>
        {channelNotFoundError ? '' : isLoading ? <LoadingSpinner /> : icon}
        <p>{text}</p>
      </ButtonContainer>
    </Badge>;
}