import { List } from 'antd';
import styled from 'styled-components';
export const ListItem = styled(List.Item)`
  background-color: #ffffff;
  &:hover {
    background-color: #f4f4f4;
  }

  transition: all 0.4s ease;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
export const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1281px) {
    display: none;
  }
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DateCreated = styled.div`
  display: flex;
  justify-content: center;
  width: 120px;
  margin-left: 8px;
  font-weight: bold;
`;
export const Description = styled.p`
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  color: #98a9bc;
  text-overflow: ellipsis;
`;
export const UnreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Icon = styled.div`
  margin-left: -28px;
  margin-right: 8px;
  @media (max-width: 1800px) {
    margin-left: 0;
  }
  .anticon {
    color: ${({
  checked
}) => checked ? '#ff9900' : 'red'} !important;
    -webkit-transition: color 100ms linear;
    -ms-transition: color 100ms linear;
    transition: color 100ms linear;
  }
`;
export const SelectChatListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const SelectChatListButton = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  padding: 8px 16px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: white;
  transition: 0.2s;
  cursor: pointer;
  border: ${props => props.active ? '2px solid #ff9900' : '2px solid #c5c5c5'};
  color: ${props => props.active ? '#ff9900' : '#c5c5c5'};

  &:hover {
    border: ${props => props.active ? '2px solid #ff9900' : '2px solid #98a9bc'};
    color: ${props => props.active ? '#ff9900' : '#98a9bc'};
  }
`;